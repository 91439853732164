// Home ////////////////////////////////////////////////////////////////////////////////////////////////////////////////

.headline-container {
  display: flex;
  color: $site-headline-color;
}

.headline-container:before,
.headline-container:after {
  content: '';
  margin: auto 1rem;
  border-bottom: solid 1px;
  flex: 1;
}

.headline-div {
  background: $site-headline-border-gradient;
  border-radius: 50%;
  padding: 2px;
  box-shadow: 0 0 0 1px $site-bg-color;
}

.headline-div span {
  display: inline-block;
  width: 2.5rem;
  height: 2.5rem;
  background: $site-bg-color;
  text-align: center;
  padding: 0.3rem;
  border-radius: 50%;
}

.headline-div span:before {
  background: $site-headline-icon-gradient;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#gradient-horizontal {
  --color-stop-1: #df011a;
  --color-stop-2: #c6776a;
  --color-stop-3: #c6776a;
}

#gradient-vertical {
  --color-stop-1: #c6776a;
  --color-stop-2: #df011a;
  --color-stop-3: #df011a;
}

.headline-icon {
  vertical-align: -0.5rem;
}

.headline-icon * {
  fill: url(#gradient-vertical) $site-headline-color;
}

// About ///////////////////////////////////////////////////////////////////////////////////////////////////////////////


// Contact /////////////////////////////////////////////////////////////////////////////////////////////////////////////