// Body ////////////////////////////////////////////////////////////////////////////////////////////////////////////////

body {
  margin: 0;
  font-family: $site-font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $site-color;
  background: $site-bg-color;
}

code {
  font-family: $site-code-font-family;
}

// Headline ////////////////////////////////////////////////////////////////////////////////////////////////////////////

h1, h2, h3, h4, h5, h6 {
  font-family: $site-headline-font-family;
  font-weight: $site-headline-font-weight;
  color: $site-headline-color;
}

h1 {
  font-size: 1.3em;
}

h2 {
  font-size: 1.2em;
}

h3 {
  font-size: 1.1em;
}

// Hr //////////////////////////////////////////////////////////////////////////////////////////////////////////////////

hr {
  color: $navbar-bg-color;
}

// Image ///////////////////////////////////////////////////////////////////////////////////////////////////////////////


// Button //////////////////////////////////////////////////////////////////////////////////////////////////////////////

.btn-link:focus,
.btn-link:active:focus {
  outline: none;
}

.btn-link:focus {
  text-decoration: none;
}

.btn-link:hover {
  text-decoration: underline;
}

// Link ////////////////////////////////////////////////////////////////////////////////////////////////////////////////

a {
  color: $site-btn-color;
}

a:hover {
  color: $site-btn-color-hover;
}

.a-link {
  text-decoration: none;
}

.a-link:hover {
  text-decoration: underline;
}

// Checkbox ////////////////////////////////////////////////////////////////////////////////////////////////////////////

.checkbox-lg {
  width: 24px;
  height: 24px;
}

// Radiobutton /////////////////////////////////////////////////////////////////////////////////////////////////////////

.radio-lg {
  width: 24px;
  height: 24px;
}

// Spacing /////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Text ////////////////////////////////////////////////////////////////////////////////////////////////////////////////

.txt-grt {
  -webkit-text-fill-color: transparent;
  background: -moz-linear-gradient(90deg, #fa0 0, #f85d00 100%);
  background: -webkit-linear-gradient(90deg, #fa0, #f85d00);
  background: linear-gradient(90deg, #fa0, #f85d00);
  -webkit-background-clip: text;
}