// Header //////////////////////////////////////////////////////////////////////////////////////////////////////////////

.site-logo {
  font-family: $site-logo-font-family;
  font-size: 1.0em;
  font-weight: 600;
  text-shadow: 0 0 8px $site-header-logo-color;
  letter-spacing: 0.3em;
  -webkit-transform: scale(0.55, 1.0);
  -moz-transform: scale(0.55, 1.0);
  -ms-transform: scale(0.55, 1.0);
  -o-transform: scale(0.55, 1.0);
  transform: scale(0.55, 1.0);
}

.navbar-brand {
  margin-right: 0;
}

.bg-dark {
  background-color: $navbar-bg-color !important;
}

a.bg-dark:hover,
a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: $site-btn-bg-color !important;
}