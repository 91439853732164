// Typography //////////////////////////////////////////////////////////////////////////////////////////////////////////

// Body
$site-font-family: 'Segoe UI', sans-serif;
$site-font-size: 1.0rem;
$site-font-weight: 400;
$site-line-height: 1.5;
$site-letter-spacing: 0.01em;

// Header
$site-logo-font-family: 'LaBeauteHeader', sans-serif;

// Headline
$site-headline-font-family: 'Segoe UI', sans-serif;
$site-headline-font-weight: 400;

// Code
$site-code-font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;

// Colors //////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Body
$site-bg-color: #f7f7f7; // rgba(138, 171, 185, 152);
$site-color: rgba(41, 51, 55, 45); // #454545;

// Header
$site-header-logo-color: rgba(255, 255, 255, 0.6);

// Navbar
$navbar-bg-color: #000000;

// Headline
$site-headline-color: #8b0015;

$site-headline-first-border-color: #df011a;
$site-headline-second-border-color: #c6776a;
$site-headline-third-border-color: #c6776a;
$site-headline-border-gradient: linear-gradient(to top, $site-headline-first-border-color, $site-headline-second-border-color);
$site-headline-icon-gradient: $site-headline-border-gradient;

// Hr
$site-hr-color: $site-color;

// Borders
$site-border-color: lighten(#abb2a2, 10%);

// Buttons
$site-btn-bg-color: #1d2124;
$site-btn-color: $site-color; // #3490dc;
$site-btn-color-hover: #1d2124;

// Footer
$site-footer-tagline-color: rgba(0, 0, 0, 0.4)