// Footer //////////////////////////////////////////////////////////////////////////////////////////////////////////////

.site-header-tagline {
  font-family: 'LaBeauteHeader', sans-serif;
  text-shadow: 0 0 0.5px $site-footer-tagline-color;
  letter-spacing: 0.01em;
  -webkit-transform: scale(0.8, 1.0);
  -moz-transform: scale(0.8, 1.0);
  -ms-transform: scale(0.8, 1.0);
  -o-transform: scale(0.8, 1.0);
  transform: scale(0.8, 1.0);
}

.facebook-icon {
  color: #4267B2;
}

.instagram-icon {
  color: #f15245;
  /*border-radius: 20%;*/
  /*background: radial-gradient(circle at 33% 100%, #fed373 4%, #f15245 30%, #d92e7f 62%, #9b36b7 85%, #515ecf)*/
}

.twitter-icon {
  color: #1DA1F2;
}