@font-face {
  font-family: 'LaBeauteHeader';
  src: url('../fonts/LaBeauteHeader.woff2') format('woff2'), url('../fonts/LaBeauteHeader.woff') format('woff'), url('../fonts/LaBeauteHeader.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'Rajdhani';
  src: url('../fonts/Rajdhani.woff2') format('woff2'), url('../fonts/Rajdhani.woff') format('woff'), url('../fonts/Rajdhani.ttf') format('truetype');
  font-style: normal;
}